.slide img {
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
    opacity: 0;
}

.slide h1 {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    opacity: 0;
}

.slide p,
.slide button {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0;
}

.slide.animateIn.previous img,
.slide.current img,
.slide.animateIn.next img
 {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
	-webkit-transition-delay: .5s;
            transition-delay: .5s;
    opacity: 1;
}

.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p
 {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
	-webkit-transition-delay: .2s;
            transition-delay: .2s;
    opacity: 1;
}

.slide.animateIn.previous button,
.slide.current button,
.slide.animateIn.next button
 {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
	-webkit-transition-delay: 1.5s;
            transition-delay: 1.5s;
    opacity: 1;
}

.slide.animateIn.previous img,
.slide.animateIn.next img {
	-webkit-transition-delay: .8s;
	        transition-delay: .8s;
}

.slide.animateIn.previous button,
.slide.animateIn.next button {
	-webkit-transition-delay: 1.3s;
	        transition-delay: 1.3s;
}

.slide.animateIn.previous h1,
.slide.animateIn.next h1,
.slide.animateIn.previous p,
.slide.animateIn.next p {
	-webkit-transition-delay: 1.1s;
	        transition-delay: 1.1s;
}

.slide.animateOut h1,
.slide.animateOut p {
        -webkit-transition-delay: .1s;
			transition-delay: .1s;
}
